import Animation from "@components/elements/Animation"
import Slider from "@components/elements/Slider/TeamSlider"
import ChevronLeft from "@components/icons/ChevronLeft"
import ChevronRight from "@components/icons/ChevronRight"
import clsx from "clsx"
import React from "react"
import { Container } from "react-bootstrap"
import "./SliderModule.scss"
import { parseHTML } from "@lib/utils"

const SliderTeamModule = ({
  title,
  description,
  sliderChildren,
  wrapperClassname,
  size,
  sectionClassname,
  length,
  showArrows,
  noSliderArrows,
  id,
}) => {
  const sliderRef = React.useRef(null)
  const [currentSlide, setCurrentSlide] = React.useState(0)

  const handleNext = () => {
    if (
      currentSlide ===
      Number(length) - Number(sliderRef.current?.props.slidesToShow)
    )
      return
    sliderRef.current?.slickGoTo(currentSlide + 1)
    setCurrentSlide(currentSlide + 1)
  }

  const handlePrev = () => {
    if (currentSlide === 0) return
    sliderRef.current?.slickGoTo(currentSlide - 1)
    setCurrentSlide(currentSlide - 1)
  }

  return (
    <Animation
      animateInType="up"
      className={clsx(
        "slider-module-wrap",
        wrapperClassname,
        sectionClassname || "section-mp"
      )}
    >
      <Container className="slider-module-container" id={id}>
        <div className="top-section">
          <div className="content-section">
            {/* {heading && (
              <p className="heading">
                <span>{heading}</span>
              </p>
            )} */}
            {title &&<h2 className="title">
              <span>{title}</span>
            </h2>}
            <p>{parseHTML(description)}</p>
          </div>
          {showArrows && (
            <div className="slider-arrow-btn-section">
              <button
                className={clsx(
                  "arrow-btn button button-white prev",
                  currentSlide === 0 && "disabled"
                )}
                onClick={handlePrev}
              >
                <ChevronLeft />
              </button>
              <button
                className={clsx(
                  "arrow-btn button button-white next",
                  currentSlide ===
                    Number(length) -
                      Number(sliderRef.current?.props.slidesToShow) &&
                    "disabled"
                )}
                onClick={handleNext}
              >
                <ChevronRight />
              </button>
            </div>
          )}
        </div>
        {sliderChildren && (
          <div className="slider-section">
            <Slider
              size={size}
              length={length}
              ref={sliderRef}
              draggable={!showArrows}
              noArrows={noSliderArrows}
            >
              {sliderChildren}
            </Slider>
          </div>
        )}
        {/* {ctaText && (
          <>
            <div className="divider" />
            <CTAText data={ctaText} />
          </>
        )} */}
      </Container>
    </Animation>
  )
}

export default SliderTeamModule
