import { ShowProcessedImage } from "@common/ggfx-client/module/components/show-image"
import { SliderLinkComp } from "@components/PropertyCard/PropertyCard"
import PlayVideoButton from "@components/elements/PlayVideo/PlayVideoButton"
import BedIcon from "@components/icons/BedIcon"
import LocationIcon from "@components/icons/LocationIcon"
import PhoneIcon from "@components/icons/PhoneIcon"
import WhatsAppIcon from "@components/icons/WhatsAppIcon"
import useBrandInfo from "@hooks/dataHooks/useBrandInfo"
import useDeviceMedia from "@hooks/useDeviceMedia"
import noImg from "@images/noImage.png"
import { offplanResultsSaleURL, offplanResultsURL } from "@lib/urls"
import {
  addRollWhatsapp,
  capitalize,
  getWhatsAppURL,
  getWhatsBaseURL,
  getWhatsRedirectURL,
  gtagReportConversion,
  parseHTML,
  slugToTitle,
  slugify,
} from "@lib/utils"
import clsx from "clsx"
import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import imageConfig from "../../../static/images/config.json"
import "./SponsoredProjects.scss"
import OffCurrencyPrice from "@components/CurrencyPrice/OffCurrencyPrice"
import { useGlobalStore } from "@stores/globalStore"
import GGFXImage from "@components/elements/GGFXImage"
import WhatsAppIconGreen from "@components/icons/WhatsAppIconGreen"
import PhoneIconPC from "@components/icons/PhoneIconPC"
import useDevelopers from "@hooks/dataHooks/useDevelopers"
import MailIcon from "@components/icons/MailIcon"
import { RegisterInterestModal } from "@components/RegisterInterestModal/RegisterInterestModal"
import { offCurrConvertPrice } from "@components/CurrencyConverter/CurrConvertPrice"
import { seoConData } from "@components/SearchResults/SearchFilters/filterOptions"

const SponsoredProjects = ({
  image,
  images,
  buldingType,
  videoURL,
  description,
  title,
  developer,
  location,
  future_launch,
  minsBeds,
  maxBeds,
  displayBedrooms,
  slug,
  community,
  slider,
  listView,
  completionYear,
  showProcessedImage,
  ggfx_results,
  strapi_id,
  price,
  notalgo,
  property,
  ads_mobile_image,
  isAds,
  themeDefault
}) => {
  const { phone } = useBrandInfo()
  const { isTablet, isMobile } = useDeviceMedia()
  const { language } = useGlobalStore()
  const developers = useDevelopers()
  let developerLogo = developers?.find(item => item.name === developer)
  const bedrooms =
    displayBedrooms !== null && displayBedrooms !== "0" ? displayBedrooms :
      // minbeds = 2, maxbeds = 4 =>  2, 3, 4
      Array.from({ length: maxBeds - minsBeds + 1 }, (_, i) => i + minsBeds).join(
        ", "
      )

  const buildingText = buldingType?.filter(type => type !== "branded").join(", ");

  const developerSlug = slugify(developer)
  let urlSlug = typeof slug === "string" ? slug : language?.value === "EN" ? slug?.en : language?.value === "RUS" ? slug?.ru : slug.en
  const propertyURL = `${offplanResultsURL + urlSlug}/`

  const LinkComponent = isTablet ? Link : Link
  const linkProps = isTablet
    ? { to: propertyURL }
    : { to: propertyURL }

  const DevComp = developerSlug ? Link : "p"
  // if completion year is in the past, then the property is ready
  const isReady = completionYear
    ? new Date(new Date(completionYear)?.setMonth(12)) < new Date()
    : false
  // State to store the randomly selected theme (either "dark" or "light")
  const [theme, setTheme] = useState(themeDefault);
  const [loadon, setLoadOn] = useState(false);
  // Randomly assign "dark" or "light" on component mount
  useEffect(() => {
    const randomTheme = Math.random() < 0.5 ? "dark" : "light";
    setTheme(themeDefault ? themeDefault : randomTheme);
    setTimeout(()=> {
      setLoadOn(true)

    },10)
  }, []); // Empty dependency array ensures this runs once on component mount

  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const { currencyType } = useGlobalStore()

  function convertToInternationalCurrencySystem(labelValue) {
    return Math.abs(Number(labelValue)) >= 1.0e+9
      ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(2) + "B"
      : Math.abs(Number(labelValue)) >= 1.0e+6
      ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(2) + "M"
      : Math.abs(Number(labelValue)) >= 1.0e+3
      ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(0) + "K"
      : Math.abs(Number(labelValue));
  }

  const conPrice = offCurrConvertPrice(price);
  const whatsPrice = (price && (typeof price === "number" || !isNaN(Number(price.toString().replace(/,/g, ""))))) ? currencyType?.value + convertToInternationalCurrencySystem(conPrice.replace(/,/g, "")) : price ? price : "TBA"
        const { leadSource } = useGlobalStore()
        let seoSourceName = seoConData.filter((item) => item.id.toString() === leadSource)
        const utmId = seoSourceName && seoSourceName?.length > 0 ? `?utm_source=${seoSourceName[0]?.id}` : ""
  return (
    <div className={clsx("offplan-card-wrap sponsor", listView && "list-view", theme)}>
      <div className="img-section">
        <div className="flag-section">
          {videoURL &&
            <PlayVideoButton videoUrl={videoURL} alternateButton />}
          {buildingText && (
            <p className="img-tag">
              <span>Sponsored Project</span>
            </p>
          )}
          {buildingText && (
            <p className="img-tag tag-new">
              <span>{buildingText}</span>
            </p>
          )}
        </div>
        {/* {completionYear &&
        <div className="flag-section ready-flag">
          <p className="img-tag">
            <span>{isReady ? "Ready" : completionYear}</span>
          </p>
        </div>} */}
        <SliderLinkComp
          slider={slider}
          url={propertyURL}
          LinkComponent={LinkComponent}
          linkProps={linkProps}
          className={clsx("img-section", listView && "listview-img-section")}
        >
          {/* {JSON.stringify(image)} */}
          {/* <LinkComponent {...linkProps} className="title"> */}
          {/* {console.log(image)}
          {console.log((isMobile && ads_mobile_image) ?  "off-plan.ads_image.tile_mob_img_big" : (isAds && theme === "dark") ? "off-plan.ads_image.tile_img_big" : (isAds && theme === "light") ? "off-plan.ads_image.tile_img" : theme === "dark" ? "off-plan.images.tile_img_big" : theme === "light" ? "off-plan.images.tileimg" : "")}
          {console.log(strapi_id)}
          {console.log(ggfx_results)} */}
          {((isMobile && ads_mobile_image) || (ads_mobile_image && theme === "light")) ? <>
            {/* {console.log("image", strapi_id)} */}
          <GGFXImage
              ImageSrc={ads_mobile_image}
              altText={"offplan-img"}
              imagetransforms={
                ggfx_results
              }
              renderer="srcSet"
              imagename={"off-plan.ads_image.tile_mob_img_big"}
              strapiID={strapi_id}
            /></> : loadon ?
            <>
            {/* {console.log("image", strapi_id)} */}
            <GGFXImage
              ImageSrc={image}
              altText={"offplan-img ads Image"}
              imagetransforms={
                ggfx_results
              }
              renderer="srcSet"
              // imagename={"off-plan.ads_image.tile_img_big"}
              imagename={theme === "dark" ? "off-plan.images.tile_img_big" : theme === "light" ? "off-plan.images.tileimg" : ""}
              strapiID={strapi_id}
            />
            {/* <img src={image.url} alt="test" /> */}
            </> : null}
          {/* </LinkComponent> */}

        </SliderLinkComp>
      </div>

      <div className="content-section">
      <LinkComponent {...linkProps} className="title">
        <div className="d-flex align-items-start justify-content-between minu">
          <LinkComponent {...linkProps} className="title">
            {title} {community ? `at ${slugToTitle(community)}` : ""}
          </LinkComponent>
          {developerLogo?.logo && theme === "light" &&
            <div className="logo inline-lgo d-none d-lg-block">
              <DevComp
            to={`${offplanResultsSaleURL}developed-by-${developerSlug}/`}
            className="developer"
          >
              <GGFXImage
                ImageSrc={developerLogo?.logo}
                altText={`developer-${developerLogo?.name}-image`}
                imagetransforms={developerLogo?.ggfx_results}
                renderer="srcSet"
                imagename="developer.logo.slider_logo_mini"
                strapiID={developerLogo?.strapi_id}
                lazyLoading
              /></DevComp></div>}
        </div>
        {developer && (
          <DevComp
            to={`${offplanResultsSaleURL}developed-by-${developerSlug}/`}
            className="developer"
          >
            by <span>{slugToTitle(developer)}</span>
          </DevComp>
        )}
        {/* {price && price !== "TBA" &&
          <div className="price">
            <span>Starting Price </span>
            <OffCurrencyPrice price={price} />
          </div>} */}
        {(price && (typeof price?.replace(/,/g, "") === "number" || !isNaN(Number(price?.replace(/,/g, ""))))) ?
          <div className="price">
            <span>Starting Price </span>
            <OffCurrencyPrice price={price} />
          </div> : <div className="price">
            <span>Starting Price </span>
            {price ? price : "TBA"}
          </div>}
        <div className="more-info">
          <p className="location">
            <LocationIcon blue />
            <span>{location}</span>
          </p>
          {typeof bedrooms === "string" ?
            <p className="beds">
              <BedIcon />
              <span>{bedrooms}</span>
            </p> :
            bedrooms > 0 ?
              <p className="beds">
                <BedIcon />
                <span>{bedrooms}</span>
              </p> : null}
        </div>
        {description && (
          <p className="description">
            <span>{parseHTML(`${typeof description === "string" ? description?.replace(/<\/?[^>]+(>|$)/g, "")?.slice(0, 80) : language?.value === "EN" ? description?.en?.replace(/<\/?p>/g, "")?.slice(0, 80) : language?.value === "RUS" ? description?.ru?.replace(/<\/?p>/g, "")?.slice(0, 80) : description?.en?.replace(/<\/?p>/g, "")?.slice(0, 80)}...`)}</span>
            <LinkComponent {...linkProps} className="read-more-text">
              more
            </LinkComponent>
          </p>
        )}
         </LinkComponent>
        <RegisterInterestModal
          offplan={property}
          show={show}
          handleClose={handleClose}
          propertyURL={process.env.GATSBY_SITE_URL + propertyURL}
        />
        {listView && (
          <div className="cta-section">
            <button
              // to={bookAViewingURL}
              onClick={handleShow}
              className="property-cta d-none d-md-flex"

            >
              <MailIcon color="black" />
              <span>Email</span>
            </button>
            <a
              href={`tel:${phone}`}
              className="property-cta"
              onClick={() => gtagReportConversion(`tel:${phone}`)}
            >
              <PhoneIconPC />
              <span>Phone</span>
            </a>
            <a
              href={getWhatsRedirectURL(
                "971505389860",
                `Hello Provident,\n\nI would like to know more about this Off Plan Project:\n\n• Project Name: ${title}\n• Project Location: ${location}\n• Developer: ${slugToTitle(developer)}\n• Type: ${capitalize(buildingText)}\n• Starting Price:  ${whatsPrice}\n• Link: ${process.env.GATSBY_SITE_URL + propertyURL+utmId}\n\nModifying this message will prevent it from being sent to the agent.`
              )}
              target="_blank"
              className="property-cta"
              rel="noreferrer"
              onClick={() => addRollWhatsapp()}
            >
              <WhatsAppIconGreen outline green />
              <span>WhatsApp</span>
            </a>
          </div>
        )}
       
      </div>
      {developerLogo?.logo && theme === "dark" &&
        <div className="logo d-none d-lg-block">
            <DevComp
            to={`${offplanResultsSaleURL}developed-by-${developerSlug}/`}
            className="developer"
          >
          <GGFXImage
            ImageSrc={developerLogo?.logo}
            altText={`developer-${developerLogo?.name}-image`}
            imagetransforms={developerLogo?.ggfx_results}
            renderer="srcSet"
            imagename="developer.logo.slider_logo_mini"
            strapiID={developerLogo?.strapi_id}
            lazyLoading
          /></DevComp></div>}
    </div>
  )
}

const OffplanImage = ({ image, listView, index, showProcessedImage, ggfx_results, strapi_id, notalgo }) => {
  let Comp = <img onContextMenu={(e) => e.preventDefault()} draggable="false" src={image} alt="offplan-img" id={`offplan-img-${index}`} />
  if (notalgo) {
    Comp = <GGFXImage
      ImageSrc={image}
      altText={"offplan-img"}
      imagetransforms={
        ggfx_results
      }
      renderer="srcSet"
      imagename="off-plan.images.tileimg"
      strapiID={strapi_id}
    />
  }
  else if (image) {
    if (showProcessedImage) {
      Comp = (
        <ShowProcessedImage
          images={image}
          attr={{
            alt: "offplan-img",
            id: `property-image-${index}`,
          }}
          transforms={imageConfig.property.searchResults.sizes}
        />
      )
    }

  } else {
    Comp = <img onContextMenu={(e) => e.preventDefault()} draggable="false" src={noImg} alt="offplan-img" id={`offplan-img-${index}`} />
  }

  return (
    <div className={clsx("img-zoom", listView && "listview-img")}>{Comp}</div>
  )
}

export default SponsoredProjects
